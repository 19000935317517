import React from "react"
import SEO from "../components/seo"
import Posts from "../components/posts"

import "../scss/blog.scss"

const BlogPage = () => {
  return (
    <div> 
      <SEO title="Blog" keywords={[`youpal`, `youpal group`, `blog`]} />
      <section className="container blogPage">
          <Posts />
      </section>
    </div>
  )
}

export default BlogPage

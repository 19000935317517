import React, { Component } from "react"

import "../scss/posts.scss"

import NotFoundImage from "../assets/img/no-image-found.png"

class Posts extends Component {

    constructor(props) {
        super(props)

        this.state = {
            posts: [],
            imgFailed: Array(6).fill(false)
        }
    }

    fallback(id) {
        const {imgFailed} = this.state

        imgFailed[id] = true
        this.setState({
            imgFailed: imgFailed
        })
    }

    componentDidMount() {
        this.fetchPosts();
    }

    fetchPosts() {
        fetch("https://uteckie.com/wp-json/wp/v2/categories")
        .then((res) => res.json())
        .then((res) => {
            const category = res.filter((cat) => cat.slug === 'blog')[0].id
            
            if (category) {
                fetch(`https://uteckie.com/wp-json/wp/v2/posts?_embed&categories[]=${category}&page=1&per_page=6`)
                .then((result) => result.json())
                .then((result) => {
                    result = result.map((post) => {
                        return {
                            image: post._embedded['wp:featuredmedia'][0].source_url,
                            title: post.title.rendered,
                            excerpt: post.excerpt.rendered,
                            link: post.link
                        };
                    });

                    this.setState({
                        posts: result
                    });
                })
                .catch((err) => console.log(err));
            }
        })
        .catch((err) => console.log(err));
    }

    render () {
        const {posts, imgFailed} = this.state

        if (posts.length) {
            return (
                <div className="col posts">
                    <div className="col">
                        {
                            posts.map((post, index) => (
                                <div key={"news-" + index} className="latestNewsItem d-flex justify-content-center">
                                    <div className="col-lg-5" style={{ textAlign: 'right' }}>
                                        {
                                            (imgFailed[index]) ?
                                                (<img className="recImg" src={NotFoundImage} />)
                                            :
                                            (<img className="recImg" src={post.image} onError={(e) => this.fallback(index)} />)
                                        }
                                    </div>
                                    <div className="col">
                                        <h5><a href={post.link} target="_blank">{post.title}</a></h5>
                                        <span className="latestNewsDescription" dangerouslySetInnerHTML={{__html: post.excerpt}}></span>
                                        <span><a href={post.link}>Read more</a></span>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            )
        }
        else {
            return (<><h2 className="text-center">Loading...</h2></>)
        }
    }

}

export default Posts
  